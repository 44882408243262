<template>
    <div class="Projectstatistics">
        <el-row :gutter="24">
             <el-col
                :xs="{span: 24}"
                :span="12"
                :lg="{span: 6}"
                class="margin-bottom-24">
                <div class="page_content">
                    <!-- <div class="chart_card_header">
                        <div class="meta">
                            <span>总金额</span>
                        </div>
                        <div class="total">
                            <span>￥32000</span>
                        </div>
                    </div>
                    <div class="chart_card_content">
                        <div class="content_fix">
                            <div>
                                <div class="ant_pro_trend">
                                    <span><span>国家级:</span><span class="item_text"> ￥20000 </span></span>
                                </div>
                                <div class="ant_pro_trend">
                                    <span><span>省级:</span><span class="item_text"> ￥8000 </span></span>
                                </div>
                                <div class="ant_pro_trend">
                                    <span><span>校级:</span><span class="item_text"> ￥4000 </span></span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="chart_card_footer">
                        <!-- <div class="field">
                            月均金额￥<span>￥ 23456</span>
                        </div> -->
                    </div>
                </div>
             </el-col>
             <el-col
                :xs="{span: 24}"
                :span="12"
                :lg="{span: 6}"
                class="margin-bottom-24">
                <div class="page_content">
                    <!-- <div class="chart_card_header">
                        <div class="meta">
                            <span>已报销金额</span>
                        </div>
                        <div class="total">
                            <span>￥25000</span>
                        </div>
                    </div>
                    <div class="chart_card_content">
                        <div class="content_fix">
                            <div>
                                <div class="ant_pro_trend">
                                    <span><span>国家级:</span><span class="item_text"> ￥18000 </span></span>
                                </div>
                                <div class="ant_pro_trend">
                                    <span><span>省级:</span><span class="item_text"> ￥5000 </span></span>
                                </div>
                                <div class="ant_pro_trend">
                                    <span><span>校级:</span><span class="item_text"> ￥2000 </span></span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="chart_card_footer">
                        <!-- <div class="field">
                            本年新增:<span>16</span>
                        </div> -->
                    </div>
                </div>  
             </el-col>
             <el-col
                :xs="{span: 24}"
                :span="12"
                :lg="{span: 6}"
                class="margin-bottom-24">
                <div class="page_content">
                    <!-- <div class="chart_card_header">
                        <div class="meta">
                            <span>待报销金额</span>
                        </div>
                        <div class="total">
                            <span>￥5000</span>
                        </div>
                    </div>
                    <div class="chart_card_content">
                        <div class="content_fix">
                            <div>
                                <div class="ant_pro_trend">
                                    <span><span>国家级:</span><span class="item_text"> ￥2000 </span></span>
                                </div>
                                <div class="ant_pro_trend">
                                    <span><span>省级:</span><span class="item_text"> ￥3000 </span></span>
                                </div>
                                <div class="ant_pro_trend">
                                    <span><span>校级:</span><span class="item_text"> ￥2000 </span></span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="chart_card_footer">
                        <!-- <div class="field">
                            转化率<span>60%</span>
                        </div> -->
                    </div>
                </div>
             </el-col>
             <el-col
                :xs="{span: 24}"
                :span="12"
                :lg="{span: 6}"
                class="margin-bottom-24">
                <div class="page_content">
                    <!-- <div class="chart_card_header">
                        <div class="meta">
                            <span>运营活动效果</span>
                        </div>
                        <div class="total">
                            <span>78%</span>
                        </div>
                    </div>
                    <div class="chart_card_content">
                        <div style="height: 48px;padding-top: 18px">
                            <el-progress
                                :text-inside="true"
                                :stroke-width="16"
                                :percentage="78"
                                color="#13c2c2"
                                status="success"/>
                        </div>
                    </div> -->
                    <div class="chart_card_footer">
                        <!-- <div class="field">
                            <div>
                                <div class="ant_pro_trend">
                                    <span><span>周同比</span><span class="item_text"> 12% </span></span>
                                </div>
                                <div class="ant_pro_trend">
                                    <span><span>日同比</span><span class="item_text"> 11% </span></span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
             </el-col>
        </el-row>
        <el-card style="border:none;box-shadow:none;position: relative;">
            <el-tabs style="margin-bottom: -20px">
                <el-tab-pane 
                    value="sales" 
                    lazy>
                    <span
                    slot="label"
                    class="font-16 page-tabs-label">经费统计</span>
                    <el-row>
                        <el-col
                            :xs="{span:24}"
                            :sm="{span:24}"
                            :md="{span:24}"
                            :lg="{span:24}"
                            :xl="{span:24}">
                            <h4>年经费统计</h4>
                            <ve-histogram
                                :extend="salesExtend"
                                :data="saleData"
                                :colors="['#4cda64', '#ff9503', '#007aff']"
                                :legend-visible="false"
                                judge-width
                                height="320px"
                            />
                        </el-col>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
        </el-card>
    </div>
</template> 
<script>
// import VeLine from 'v-charts/lib/line.common.min'
import VeHistogram from 'v-charts/lib/histogram.min'
import 'echarts/lib/component/dataZoom'
export default {
    components: {
        // VeLine,
        VeHistogram,
    },
    data () {
        this.salesExtend = {
            series(v) {
                v.forEach(i => {
                    i.symbol = 'none'
                    i.barCategoryGap = 40
                    // i.barWidth = 50
                })
                return v
            },
            label: {
                show: true,      //开启显示
                position: 'top', //在上方显示
                textStyle: {     //数值样式
                    color: 'black',
                    fontSize: 16
                }
            },
            legend: {
                data: ['待报销金额', '已报销金额', '总金额'],
            },
            xAxis:[
                {
                    type : 'category',
                }
            ]
        }
        return {
            saleData: {
                // columns: ['月份', '待报销金额','已报销金额','总金额'],
                // rows: [
                //     { 月份: '1月', 待报销金额: 1000,已报销金额:1800,总金额:2800, },
                //     { 月份: '2月', 待报销金额: 500,已报销金额:2000,总金额:2500, },
                //     { 月份: '3月', 待报销金额: 800,已报销金额:2300,总金额:3100, },
                //     { 月份: '4月', 待报销金额: 300,已报销金额:1700,总金额:2000, },
                //     { 月份: '5月', 待报销金额: 100,已报销金额:1500,总金额:1600, },
                //     { 月份: '6月', 待报销金额: 700,已报销金额:2300,总金额:3000, },
                //     { 月份: '7月', 待报销金额: 600,已报销金额:3200,总金额:3800,},
                //     { 月份: '8月', 待报销金额: 400,已报销金额:3600,总金额:4000, },
                //     { 月份: '9月', 待报销金额: 200,已报销金额:1200,总金额:1400, },
                //     { 月份: '10月', 待报销金额: 700,已报销金额:2000,总金额:2700, },
                //     { 月份: '11月', 待报销金额: 600,已报销金额:1500,总金额:2100, },
                //     { 月份: '12月', 待报销金额: 1100,已报销金额:1900,总金额:3000, }
                // ]
            },
        }
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/Projectstatistics.css';
</style>
<style>
.Projectstatistics .el-col{
    padding: 0 12px;
}
.margin-bottom-24{
    margin-bottom:24px;
}
</style>